import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',//改为history模式，去除url里的#
    //base: 'Website',
    routes: [
        { path: '/',redirect: '/home'},
        { path: '/home',
          component: () => import('./components/Home.vue'),
          redirect: '/index',
          children:[
            {
              path: '/index',
              component: () => import('@/pages/index.vue'),
            },
            {
              //快消零售
              path: '/industry-scheme',
              component: () => import('@/pages/industryScheme.vue'),
            },
            {
              //医疗健康
              path: '/medical-health',
              component: () => import('@/pages/medicalHealth.vue'),
            },{
              path: '/purchase-collaboration',
              component: () => import('@/pages/purchaseCollaboration.vue'),
            },
            {
              path: '/DPM-digital-Sheet',
              component: () => import('@/pages/DPMDigitalSheet.vue'),
            },
            {
              path: '/digital-production',
              component: () => import('@/pages/digitalProduction.vue'),
            },
            {
              path: '/APS-advanced-scheduling',
              component: () => import('@/pages/APSAdvancedScheduling.vue'),
            },
            {
              path: '/intelligent-warehousing',
              component: () => import('@/pages/intelligentWarehousing.vue'),
            },
            {
              path: '/safety-observation',
              component: () => import('@/pages/safetyObservation.vue'),
            },
            {
              //销售效能管理
              path: '/sales-effectiveness',
              component: () => import('@/pages/salesEffectiveness.vue'),
            },
            {
              //销售运营管理
              path: '/sales-operation',
              component: () => import('@/pages/salesOperation.vue'),
            },
            {
              //学术会议管理
              path: '/academic-conference',
              component: () => import('@/pages/academicConference.vue'),
            },
            {
              //市场准入与商务运营
              path: '/market-access',
              component: () => import('@/pages/marketAccess.vue'),
            },
            {
              //案例精选
              path: '/selected-warehousing',
              component: () => import('@/pages/selected/selectedWarehousing.vue'),
            },
            {
              path: '/selected-marykay',
              component: () => import('@/pages/selected/selectedMarykay.vue'),
            },
            {
              path: '/selected-iDAS',
              component: () => import('@/pages/selected/selectIDAS.vue'),
            },{
              path: '/selected-migration',
              component: () => import('@/pages/selected/selectMigration.vue'),
            },{
              path: '/selected-integration',
              component: () => import('@/pages/selected/selectedIntegration.vue'),
            },{
              path: '/selected-regional-indicators',
              component: () => import('@/pages/selected/selectedRegionalIndicators.vue'),
            },{
              path: '/selected-management-system',
              component: () => import('@/pages/selected/selectedManagementSystem.vue'),
            },{
              path: '/selected-business',
              component: () => import('@/pages/selected/selectedBusiness.vue'),
            },{
              path: '/selected-dashboard',
              component: () => import('@/pages/selected/selectedDashboard.vue'),
            },{
              path: '/selected-5GF',
              component: () => import('@/pages/selected/selected-5GF.vue'),
            },{
              path: '/selected-line',
              component: () => import('@/pages/selected/selectedLine.vue'),
            },{
              path: '/aboutus',
              component: () => import('@/pages/aboutUs.vue'),
            },{
              path: '/joinus',
              component: () => import('@/pages/joinUs.vue'),
            },{
              path: '/news',
              component: () => import('@/pages/news.vue'),
              children : [
                {
                  path: '/news-1',
                  component: () => import('@/pages/news-more/news-1.vue'),
                },{
                  path: '/news-2',
                  component: () => import('@/pages/news-more/news-2.vue'),
                },{
                  path: '/news-3',
                  component: () => import('@/pages/news-more/news-3.vue'),
                },
              ]
            },{
              //技术服务
              path: '/custom-development',
              component: () => import('@/pages/technicalService/customDevelopment.vue'),
            },{
              //快速交付
              path: '/fast-delivery',
              component: () => import('@/pages/technicalService/fastDelivery.vue'),
            },{
              //数据湖仓
              path: '/data-warehouse',
              component: () => import('@/pages/technicalService/dataWarehouse.vue'),
            },
          ]
        },
      ]

})



//挂载路由导航守卫
// router.beforeEach((to,from,next) => {
//   if(to.path === '/login') return next()
//   //获取token
//   const tokenStr = window.sessionStorage.getItem('token')
//   //没有token，强制跳转到登录页
//   if(!tokenStr) return next('/login')

//   next()
// })
router.beforeEach((to, from, next) => {
     // 让页面回到顶部
     document.documentElement.scrollTop = 0
     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
 })
export default router
