import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
export default new Vuex.Store({
    state: {
        /* 当前页面的位置 */
        activeIndex:'1',
        /*是否是新闻详情*/
        newsIndex: false,
        
    },
    mutations: {          
        setIndexInfo(data,news){
            state.activeIndex=data;
            state.newsIndex= news;
        },
    },
    actions: {},
    modules: {}
})