<template>
    <el-container>
        <el-header>
            <Header></Header>
        </el-header>
        <el-main>
            <router-view></router-view>
        </el-main>
        <el-footer>
            <Footer></Footer>
        </el-footer>
    </el-container>
</template>

<script>
import Header from "./header/header.vue"
import Footer from "./footer/footer.vue"
export default {
    name: 'bizFocusHome',
    components:{Header,Footer},
    created() {
    },
    data() {
        return {
        };
    },

    mounted() {
        
    },

    methods: {
    },
};
</script>

<style lang="less" scoped>
 .el-header {
    background-color: rgb(0 0 0 / 0%);
    color: #fff;
    text-align: center;
    line-height: 6rem;
    z-index: 99;
    width: 190.1rem;
  }
.el-footer {
    margin: auto;
    width: 190.1rem;
  }
  
  .el-main {
    margin: auto;
    width: 190.1rem;
    background-color: #fff;
    text-align: center;
    padding: 0;
    margin-top: -10rem;
    position: relative;
    overflow: visible;
    padding-bottom: 10rem;
  }
  
</style>