<template>
    <div>
        <div class="container">
            <img
              class="logo-style"
              src="@/assets/image/logo.jpg"
            />
            <el-menu :default-active="activeIndex" class="el-menustyle" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1" class="el-item" @click="jumpRout('/index','1')">首页</el-menu-item>
                <el-submenu index="2" class="el-item" :style="{'border-bottom': activeIndex === '2'?'0.2rem solid #09ff00' : '0rem solid'}" @click.native="jumpRout('/industry-scheme','2')">
                    <template slot="title">业务与解决方案</template>
                    <div class="industry-scheme">
                        <div class="industry-scheme-item">
                            <div class="industry-scheme-title" >
                                <div class="item" @click="jumpRout('/digital-production','2')"><span>智慧监管执法</span></div>
                                <div class="item" @click="jumpRout('/intelligent-warehousing','2')"><span>智慧疾病控制</span></div>
                                <div class="item" @click="jumpRout('/APS-advanced-scheduling','2')"><span>智慧健康体检</span></div>
                            </div>
                        </div>
                       <div class="industry-scheme-item" style="margin-left:0">
                          <div class="industry-scheme-title">
                              <div class="item" style="width:50%" @click="jumpRout('/sales-effectiveness','2')"><span>智慧医防协同</span></div>
                          </div>
                       </div>
                    </div>
                </el-submenu>
                <el-submenu index="3" class="el-item" :style="{'border-bottom': activeIndex === '3'?'0.2rem solid #09ff00' : '0rem solid'}">
                    <template slot="title">健康服务</template>
                    <div class="industry-scheme">
                      <div class="industry-scheme-item">
                        <div class="industry-scheme-title" >
                          <div class="item" @click="jumpRout('/digital-production','2')"><span>健康监管服务</span></div>
                          <div class="item" @click="jumpRout('/intelligent-warehousing','2')"><span>健康体检服务</span></div>
                          <div class="item" @click="jumpRout('/APS-advanced-scheduling','2')"><span>健康监测服务</span></div>
                        </div>
                      </div>
                      <div class="industry-scheme-item" style="margin-left:0">
                        <div class="industry-scheme-title">
                          <div class="item" style="width:50%" @click="jumpRout('/sales-effectiveness','2')"><span>健康公众服务</span></div>
                        </div>
                      </div>
                    </div>
                </el-submenu>
                <el-menu-item index="4" class="el-item" @click.native="jumpRout('/custom-development','4')">
                    <!-- <div class="font-style">
                        技术服务
                        <div class="list">11</div>
                    </div> -->

                    <el-dropdown placement="bottom">
                        <div class="el-dropdown-link font-style" >
                            技术服务
                        </div>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item command="a" @click.native="jumpRout('/data-warehouse','4')">数据湖仓技术</el-dropdown-item>
                            <el-dropdown-item command="b">实施及运维</el-dropdown-item>
                            <el-dropdown-item command="c" @click.native="jumpRout('/custom-development','4')">定制开发</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-menu-item>
                <el-menu-item index="5" class="el-item" @click="jumpRout('/news','5')">新闻资讯</el-menu-item>
                <el-menu-item index="6" class="el-item" @click="jumpRout('/aboutus','6')">关于我们</el-menu-item>
                <el-menu-item index="7" class="el-item" @click="jumpRout('/joinus','7')">加入我们</el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<script>
import store from '@/plugins/store.js';
export default {
    name: 'bizfocusHeader',

    data() {
        return {
            activeIndex: '1',
            newsIndex: false,
        };
    },
    watch:{
        // activeIndex(){
        //     console.log('watch更新')
        //     return store.state.activeIndex;
        // }
    },
    mounted() {
        this.activeIndex = localStorage.getItem('headId') || '1';
        this.newsIndex =  localStorage.getItem('newsIndex') || false;
        console.log("test store",this.$store.activeIndex);
        store.commit('setIndexInfo',{
            data:this.activeIndex ,
            news:this.newsIndex,});
    },

    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        jumpRout(route,index){
            this.activeIndex = index
            this.newsIndex = false
            localStorage.setItem('headId',index);
            localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push(route)
            this.activeIndex = localStorage.getItem('headId') || '1';
            store.commit('setIndexInfo',{
            data:this.activeIndex ,
            news:this.newsIndex,});
        }
    },
};
</script>

<style lang="less" scoped>
.container{
    display: flex;
    width: 139rem;
    margin: auto;
}
.el-menustyle{
    // width: 185rem;
    margin-top: 4rem;
    margin-left: -70rem;
    background-color: rgb(0 0 0 / 0%);
}
.industry-scheme-head{
    width: 7.2rem;
    height: 2.5rem;
    font-size: 1.8rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 2.5rem;
    text-align: left;
    cursor: pointer;

    margin: 3.5rem 0 0 0rem;
}
.industry-scheme-title{
    width: 100%;
     /*设置当前元素为flex模式*/
     display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem 0 0 0rem;
    text-align: left;

    .item{
        width: 33%;
        margin-top: 8rem;
        height: 3.2rem;
        font-size: 1.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 4rem;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        cursor: pointer;
        span{
            padding-bottom: 1rem;
        }
        span:hover{
            border-bottom: 0.2rem solid #11A84F;
            color: #11A84F;
            // transition: all .1s;
        }
    }
}
.industry-scheme-item{
    margin-left: 26rem;
    width: 35%;
}
.check-scheme-head{
    width: 7.2rem;
    height: 2.5rem;
    font-size: 1.8rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 2.5rem;

    margin: 2.9rem 0 0 26.2rem;
}
.check-scheme-item{
    width: 60%;
}
.check-scheme-title{
    width: 100%;
     /*设置当前元素为flex模式*/
     display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0rem 0 0 26.2rem;
    .item{
        display: flex;
        width: 50%;
        margin-top: 1rem;
        height: 3.2rem;
        font-size: 1.8rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 4rem;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        cursor: pointer;
        span{
            padding-bottom: 1rem;
            display: flex;
        }
        span:hover{
            // border-bottom: 0.2rem solid #11A84F;
            color: #11A84F;
            // transition: all .1s;
        }
    }
    .explain{
        height: 4rem;
        font-size: 1.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        line-height: 4remx;
        margin: 0.3rem 0 0 0.8rem;
    }
}
.font-style{
    // height: 1.8rem;
    color: #ffffff;
    font-family: 'CN_Regular';
    font-size: 1.8rem;
    // margin-top: -0.5rem;
}
.list{
        width: 21.8rem;
        height: 17rem;
        margin-top: 1.8rem;
        border: 1px solid;
    }
.font-style:hover{
    color: #11A84F;
}
.industry-scheme{
    height: 25rem;
    display: flex;
    background: #FDFDFD;
    width: 190rem;
    // @media screen and (max-width: 1920px) {
    //     width: 1900px;
    //   }
    //   @media screen and (max-width: 1746px) {
    //     width: 1745px;
    //   }
    //   @media screen and (max-width: 1536px) {
    //     width: 1516px;
    //   }
    //   @media screen and (max-width: 1280px) {
    //     width: 1270px;
    //   }
    // box-shadow: 0px 2px 15px 0px rgba(149,149,149,0.5);
    }
    /deep/ .el-menu--horizontal .el-menu-item{
        margin-left: 5rem;
        color: #ffffff;
        font-family: 'CN_Regular';
        font-size: 1.8rem;
    }
    /deep/ .el-menu--horizontal .el-menu-item:hover{
        margin-left: 5rem;
        color: #11A84F;
        font-family: 'CN_Regular';
        font-size: 1.8rem;
    }
    /deep/ .el-menu--horizontal .el-menu-item:hover{
        // margin: 0 2rem;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu.el-menu--horizontal{
        border-bottom: solid 0rem #e6e6e6;
    }
    /deep/ .el-menu--horizontal>.el-submenu{
        margin-left: 5rem;
    }
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title{
        color: #ffffff;
        font-family: 'CN_Regular';
        font-size: 1.8rem;
    }
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
        color: #11A84F !important;
    }
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
        color: #000000;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        border-bottom: 0.2rem solid #09ff00;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu--horizontal>.el-menu-item.is-active{
        color: #fff;
    }
    /deep/ .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom: 0.2rem solid #09ff00;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-item .el-submenu .is-opened{
        // background-color: rgb(0 0 0 / 0%);
        color: #fff;
    }
    /deep/.el-icon-arrow-down:before {
        content: "";
    }
    /deep/ .el-dropdown-menu .el-popper{
        border: 1px  solid #f00;
    }
</style>
