import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import Antd from "ant-design-vue";
import store from './plugins/store'
import "ant-design-vue/dist/antd.css";
import './plugins/element.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//导入字体图标
import './assets/fonts/iconfont.css'
import './assets/styles/font.css'

//导入全局样式
import './assets/css/global.css'
import './assets/css/common.css'
import './assets/css/common.less'

import axios from 'axios'
//配置请求的根路径
axios.defaults.baseURL = 'http://127.0.0.1:8888/api/private/v1/'
axios.interceptors.request.use(config =>{
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Antd)
new Vue({
  router,
  store,
  Antd,
  render: h => h(App)
}).$mount('#app')
