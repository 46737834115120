<template>
    <div class="footer-container">
        <div class="contain-1">
            <img
              class="contain1-logo"
              src="@/assets/image/logo.jpg"
            />
            <div class="box1">
                <img class="img" src="@/assets/image/footer/地址.png"/>
                <div class="content">北京市昌平区北七家镇未来科学城南区英才南一街3号院2号楼5层508</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img class="img" src="@/assets/image/footer/电话@2x.png"/>
                <div class="content">13121011889</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img class="img" src="@/assets/image/footer/邮箱@2x.png"/>
                <div class="content">majm@mtm2000.cn</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a class="content" href="https://beian.miit.gov.cn" target="_blank">京ICP备2023040996号</a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesFooter',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.footer-container{
    width: 100%;
    height: 18rem;
    background: #1E1E37;
    display: flex;
}
.contain-1{
    margin: 3rem 0 0 7rem;
    width: 100%;
    .box1{
        display: flex;
        margin-top: 3.5rem;
        .img{
            width: 1.6rem;
            height: 1.6rem;
        }
        .content{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.6rem;
            margin: -1rem 0 0 0.7rem;
        }
    }
    .box2{
        display: flex;
        margin-top: 1rem;
        .img{
            width: 1.6rem;
            height: 1.6rem;
        }
        .content{
            font-size: 1.4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.6rem;
            margin: -1rem 0 0 0.7rem;
        }
    }
    .box3{
        display: flex;
        margin-top: 1rem;
        .img{
            width: 1.6rem;
            height: 1.6rem;
        }
        .content{
            font-size: 1.4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.6rem;
            margin: -1rem 0 0 0.7rem;
        }
    }
}
.contain-2{
    margin-top: 6.6rem;
    .title{
        width: 3.6rem;
        height: 1.6rem;
        font-size: 1.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 2.4rem;
    }
}
.contain-3{
    margin: 6.6rem 0 0 6.3rem;
    .title{
        height: 1.6rem;
        font-size: 1.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 2.4rem;
    }
    .content{
        font-size: 1.4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #858A9D;
        line-height: 3.2rem;
    }
}
.contain-4{
    margin: 6.6rem 0 0 6.4rem;
    .contain4-img{
        width: 10rem;
        height: 10rem;
    }
    .content{
        height: 3.6rem;
        font-size: 1.4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 3.6rem;
        text-align: center;
    }
}
.contain1-logo{
    width: 16rem;
    height: 5.2rem;
}
</style>